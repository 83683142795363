var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础用法")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.value1,
                callback: function ($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1",
              },
            },
            _vm._l(_vm.options1, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("有禁用选项")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.value2,
                callback: function ($$v) {
                  _vm.value2 = $$v
                },
                expression: "value2",
              },
            },
            _vm._l(_vm.options2, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: {
                  disabled: item.disabled,
                  label: item.label,
                  value: item.value,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("禁用状态")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-select",
            {
              attrs: { disabled: "", placeholder: "请选择" },
              model: {
                value: _vm.value1,
                callback: function ($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1",
              },
            },
            _vm._l(_vm.options1, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("可清空单选")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-select",
            {
              attrs: { clearable: "", placeholder: "请选择" },
              model: {
                value: _vm.value1,
                callback: function ($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1",
              },
            },
            _vm._l(_vm.options1, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础多选")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-select",
            {
              attrs: { multiple: "", placeholder: "请选择" },
              model: {
                value: _vm.value3,
                callback: function ($$v) {
                  _vm.value3 = $$v
                },
                expression: "value3",
              },
            },
            _vm._l(_vm.options1, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("可搜索")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-select",
            {
              attrs: { filterable: "", placeholder: "请选择" },
              model: {
                value: _vm.value1,
                callback: function ($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1",
              },
            },
            _vm._l(_vm.options1, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }