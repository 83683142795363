var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "button-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础用法")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-button", [_vm._v("默认按钮")]),
          _c("el-button", { attrs: { type: "primary" } }, [_vm._v("主要按钮")]),
          _c("el-button", { attrs: { type: "success" } }, [_vm._v("成功按钮")]),
          _c("el-button", { attrs: { type: "info" } }, [_vm._v("信息按钮")]),
          _c("el-button", { attrs: { type: "warning" } }, [_vm._v("警告按钮")]),
          _c("el-button", { attrs: { type: "danger" } }, [_vm._v("危险按钮")]),
          _c("el-button", { attrs: { plain: "" } }, [_vm._v("朴素按钮")]),
          _c("el-button", { attrs: { plain: "", type: "primary" } }, [
            _vm._v("主要按钮"),
          ]),
          _c("el-button", { attrs: { plain: "", type: "success" } }, [
            _vm._v("成功按钮"),
          ]),
          _c("el-button", { attrs: { plain: "", type: "info" } }, [
            _vm._v("信息按钮"),
          ]),
          _c("el-button", { attrs: { plain: "", type: "warning" } }, [
            _vm._v("警告按钮"),
          ]),
          _c("el-button", { attrs: { plain: "", type: "danger" } }, [
            _vm._v("危险按钮"),
          ]),
          _c("el-button", { attrs: { round: "" } }, [_vm._v("圆角按钮")]),
          _c("el-button", { attrs: { round: "", type: "primary" } }, [
            _vm._v("主要按钮"),
          ]),
          _c("el-button", { attrs: { round: "", type: "success" } }, [
            _vm._v("成功按钮"),
          ]),
          _c("el-button", { attrs: { round: "", type: "info" } }, [
            _vm._v("信息按钮"),
          ]),
          _c("el-button", { attrs: { round: "", type: "warning" } }, [
            _vm._v("警告按钮"),
          ]),
          _c("el-button", { attrs: { round: "", type: "danger" } }, [
            _vm._v("危险按钮"),
          ]),
          _c("el-button", { attrs: { circle: "", icon: "el-icon-search" } }),
          _c("el-button", {
            attrs: { circle: "", icon: "el-icon-edit", type: "primary" },
          }),
          _c("el-button", {
            attrs: { circle: "", icon: "el-icon-check", type: "success" },
          }),
          _c("el-button", {
            attrs: { circle: "", icon: "el-icon-message", type: "info" },
          }),
          _c("el-button", {
            attrs: { circle: "", icon: "el-icon-star-off", type: "warning" },
          }),
          _c("el-button", {
            attrs: { circle: "", icon: "el-icon-delete", type: "danger" },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("禁用状态")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-button", { attrs: { disabled: "" } }, [_vm._v("默认按钮")]),
          _c("el-button", { attrs: { disabled: "", type: "primary" } }, [
            _vm._v("主要按钮"),
          ]),
          _c("el-button", { attrs: { disabled: "", type: "success" } }, [
            _vm._v("成功按钮"),
          ]),
          _c("el-button", { attrs: { disabled: "", type: "info" } }, [
            _vm._v("信息按钮"),
          ]),
          _c("el-button", { attrs: { disabled: "", type: "warning" } }, [
            _vm._v("警告按钮"),
          ]),
          _c("el-button", { attrs: { disabled: "", type: "danger" } }, [
            _vm._v("危险按钮"),
          ]),
          _c("el-button", { attrs: { disabled: "", plain: "" } }, [
            _vm._v("朴素按钮"),
          ]),
          _c(
            "el-button",
            { attrs: { disabled: "", plain: "", type: "primary" } },
            [_vm._v("主要按钮")]
          ),
          _c(
            "el-button",
            { attrs: { disabled: "", plain: "", type: "success" } },
            [_vm._v("成功按钮")]
          ),
          _c(
            "el-button",
            { attrs: { disabled: "", plain: "", type: "info" } },
            [_vm._v("信息按钮")]
          ),
          _c(
            "el-button",
            { attrs: { disabled: "", plain: "", type: "warning" } },
            [_vm._v("警告按钮")]
          ),
          _c(
            "el-button",
            { attrs: { disabled: "", plain: "", type: "danger" } },
            [_vm._v("危险按钮")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("文字按钮")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-button", { attrs: { type: "text" } }, [_vm._v("文字按钮")]),
          _c("el-button", { attrs: { disabled: "", type: "text" } }, [
            _vm._v("文字按钮"),
          ]),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("elemenr内置图标按钮")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-button", { attrs: { icon: "el-icon-edit", type: "primary" } }),
          _c("el-button", {
            attrs: { icon: "el-icon-share", type: "primary" },
          }),
          _c("el-button", {
            attrs: { icon: "el-icon-delete", type: "primary" },
          }),
          _c(
            "el-button",
            { attrs: { icon: "el-icon-search", type: "primary" } },
            [_vm._v("搜索")]
          ),
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v(" 上传 "),
            _c("i", { staticClass: "el-icon-upload el-icon--right" }),
          ]),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("自定义图标按钮")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" } },
            [_c("vab-icon", { attrs: { icon: "24-hours-line" } })],
            1
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" } },
            [_c("vab-icon", { attrs: { icon: "4k-line" } })],
            1
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" } },
            [_c("vab-icon", { attrs: { icon: "a-b" } })],
            1
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" } },
            [
              _c("vab-icon", { attrs: { icon: "account-box-line" } }),
              _vm._v(" 用户名 "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("按钮组")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-button-group",
            { staticStyle: { "margin-right": "10px" } },
            [
              _c(
                "el-button",
                { attrs: { icon: "el-icon-arrow-left", type: "primary" } },
                [_vm._v("上一页")]
              ),
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v(" 下一页 "),
                _c("i", { staticClass: "el-icon-arrow-right el-icon--right" }),
              ]),
            ],
            1
          ),
          _c(
            "el-button-group",
            [
              _c("el-button", {
                attrs: { icon: "el-icon-edit", type: "primary" },
              }),
              _c("el-button", {
                attrs: { icon: "el-icon-share", type: "primary" },
              }),
              _c("el-button", {
                attrs: { icon: "el-icon-delete", type: "primary" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("加载中")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-button", { attrs: { loading: true, type: "primary" } }, [
            _vm._v("加载中"),
          ]),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("不同尺寸")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-button", [_vm._v("默认按钮")]),
          _c("el-button", { attrs: { size: "medium" } }, [_vm._v("中等按钮")]),
          _c("el-button", { attrs: { size: "small" } }, [_vm._v("小型按钮")]),
          _c("el-button", { attrs: { size: "mini" } }, [_vm._v("超小按钮")]),
          _c("el-button", { attrs: { round: "" } }, [_vm._v("默认按钮")]),
          _c("el-button", { attrs: { round: "", size: "medium" } }, [
            _vm._v("中等按钮"),
          ]),
          _c("el-button", { attrs: { round: "", size: "small" } }, [
            _vm._v("小型按钮"),
          ]),
          _c("el-button", { attrs: { round: "", size: "mini" } }, [
            _vm._v("超小按钮"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }