var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comprehensive-form-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            {
              attrs: {
                lg: { span: 12, offset: 6 },
                md: { span: 20, offset: 2 },
                sm: { span: 20, offset: 2 },
                xl: { span: 12, offset: 6 },
                xs: 24,
              },
            },
            [
              _c(
                "vab-query-form",
                [
                  _c(
                    "vab-query-form-left-panel",
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.labelPosition,
                            callback: function ($$v) {
                              _vm.labelPosition = $$v
                            },
                            expression: "labelPosition",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v("左对齐"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v("右对齐"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "top" } }, [
                            _vm._v("顶部对齐"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form",
                  attrs: {
                    "label-position": _vm.labelPosition,
                    "label-width": "100px",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动区域", prop: "region" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择活动区域" },
                          model: {
                            value: _vm.form.region,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "region", $$v)
                            },
                            expression: "form.region",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "区域一", value: "shanghai" },
                          }),
                          _c("el-option", {
                            attrs: { label: "区域二", value: "beijing" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间", prop: "date" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          placeholder: "选择日期时间",
                          type: "datetime",
                        },
                        model: {
                          value: _vm.form.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "即时配送", prop: "delivery" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.form.delivery,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "delivery", $$v)
                          },
                          expression: "form.delivery",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动性质", prop: "type" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c("el-checkbox", {
                            attrs: { label: "美食/餐厅线上活动", name: "type" },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "地推活动", name: "type" },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "线下主题活动", name: "type" },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "单纯品牌曝光", name: "type" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "特殊资源", prop: "resource" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.resource,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "resource", $$v)
                            },
                            expression: "form.resource",
                          },
                        },
                        [
                          _c("el-radio", {
                            attrs: { label: "线上品牌商赞助" },
                          }),
                          _c("el-radio", { attrs: { label: "线下场地免费" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动形式", prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评星" } },
                    [
                      _c("el-rate", {
                        attrs: { "show-text": "" },
                        model: {
                          value: _vm.form.rate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "rate", $$v)
                          },
                          expression: "form.rate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "行政区划" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          clearable: "",
                          filterable: "",
                          options: _vm.areaOptions,
                          props: { label: "name", value: "code" },
                        },
                        model: {
                          value: _vm.form.area,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "area", $$v)
                          },
                          expression: "form.area",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "穿梭框" } },
                    [
                      _c("el-transfer", {
                        attrs: {
                          data: _vm.data,
                          "filter-method": _vm.filterMethod,
                          "filter-placeholder": "请输入城市拼音",
                          filterable: "",
                        },
                        model: {
                          value: _vm.form.transfer,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "transfer", $$v)
                          },
                          expression: "form.transfer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("form")
                            },
                          },
                        },
                        [_vm._v(" 立即创建 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-left" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("form")
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }