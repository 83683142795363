var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-container" },
    [
      _c("el-page-header", {
        attrs: { content: "【" + _vm.route.query.title + "】详情页面" },
        on: { back: _vm.goBack },
      }),
      _c("el-alert", {
        attrs: {
          closable: false,
          "show-icon": "",
          title:
            "详情页菜单高亮，并缓存详情页，返回上一页再次打开可看到效果，dynamicNewTab可配置详情页只打开一个tab页还是可以根据参数不同无限打开多个，当前展示的是可以打开多个",
          type: "success",
        },
      }),
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "输入框缓存" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "text", $$v)
                  },
                  expression: "form.text",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          attrs: { icon: "el-icon-refresh", type: "primary" },
          on: { click: _vm.handleRefreshMainPage },
        },
        [_vm._v(" 刷新综合表格页面 ")]
      ),
      _c(
        "el-descriptions",
        {
          attrs: { border: "", column: 3, title: "详情" },
          scopedSlots: _vm._u([
            {
              key: "extra",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("操作")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("标题")]),
              _vm._v(" " + _vm._s(_vm.route.query.title) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("作者")]),
              _vm._v(" " + _vm._s(_vm.route.query.author) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("时间")]),
              _vm._v(" " + _vm._s(_vm.route.query.datetime) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("评级")]),
              _c("el-rate", {
                attrs: { disabled: "", value: Number(_vm.route.query.rate) },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v("备注")]),
              _vm._v(" " + _vm._s(_vm.route.query.description) + " "),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _c("i", { staticClass: "el-icon-office-building" }),
                _vm._v(" 联系地址 "),
              ]),
              _vm._v(" 江苏省苏州市吴中区吴中大道 1188 号 "),
            ],
            2
          ),
        ],
        1
      ),
      _c("vab-json-viewer", {
        attrs: { copyable: "", "expand-depth": 5, sort: "", value: _vm.route },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }