var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "100px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "付款账户", prop: "payAccount" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.payAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "payAccount", $$v)
                  },
                  expression: "form.payAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款账户", prop: "gatheringAccount" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.gatheringAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "gatheringAccount", $$v)
                  },
                  expression: "form.gatheringAccount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款人姓名", prop: "gatheringName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.gatheringName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "gatheringName", $$v)
                  },
                  expression: "form.gatheringName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转账金额", prop: "price" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pay-button-group" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
      _c("el-alert", { attrs: { closable: false } }, [
        _c("h3", [_vm._v("转账到支付宝")]),
        _c("p", [
          _vm._v(
            "生活好，支付宝。生活好，支付宝。生活好，支付宝。生活好，支付宝。"
          ),
        ]),
        _c("h3", [_vm._v("转账到微信")]),
        _c("p", [
          _vm._v(
            " 微不可挡，万众一信。微不可挡，万众一信。微不可挡，万众一信。微不可挡，万众一信。 "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }