var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "radio-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础用法")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-radio",
            {
              attrs: { label: "1" },
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            [_vm._v("备选项")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: "2" },
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            [_vm._v("备选项")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("禁用状态")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-radio",
            {
              attrs: { disabled: "", label: "禁用" },
              model: {
                value: _vm.radio2,
                callback: function ($$v) {
                  _vm.radio2 = $$v
                },
                expression: "radio2",
              },
            },
            [_vm._v("备选项")]
          ),
          _c(
            "el-radio",
            {
              attrs: { disabled: "", label: "选中且禁用" },
              model: {
                value: _vm.radio2,
                callback: function ($$v) {
                  _vm.radio2 = $$v
                },
                expression: "radio2",
              },
            },
            [_vm._v("备选项")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("单选框组")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.radio3,
                callback: function ($$v) {
                  _vm.radio3 = $$v
                },
                expression: "radio3",
              },
            },
            [
              _c("el-radio", { attrs: { label: 3 } }, [_vm._v("备选项")]),
              _c("el-radio", { attrs: { label: 6 } }, [_vm._v("备选项")]),
              _c("el-radio", { attrs: { label: 9 } }, [_vm._v("备选项")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("按钮样式")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.radio4,
                callback: function ($$v) {
                  _vm.radio4 = $$v
                },
                expression: "radio4",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "上海" } }),
              _c("el-radio-button", { attrs: { label: "北京" } }),
              _c("el-radio-button", { attrs: { label: "广州" } }),
              _c("el-radio-button", { attrs: { label: "深圳" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("带有边框")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-radio",
            {
              attrs: { border: "", label: "1" },
              model: {
                value: _vm.radio5,
                callback: function ($$v) {
                  _vm.radio5 = $$v
                },
                expression: "radio5",
              },
            },
            [_vm._v("备选项1")]
          ),
          _c(
            "el-radio",
            {
              attrs: { border: "", label: "2" },
              model: {
                value: _vm.radio5,
                callback: function ($$v) {
                  _vm.radio5 = $$v
                },
                expression: "radio5",
              },
            },
            [_vm._v("备选项2")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }