var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础用法")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", { staticClass: "demonstration" }, [_vm._v("默认")]),
          _c("el-slider", {
            model: {
              value: _vm.value1,
              callback: function ($$v) {
                _vm.value1 = $$v
              },
              expression: "value1",
            },
          }),
          _c("span", { staticClass: "demonstration" }, [
            _vm._v("自定义初始值"),
          ]),
          _c("el-slider", {
            model: {
              value: _vm.value2,
              callback: function ($$v) {
                _vm.value2 = $$v
              },
              expression: "value2",
            },
          }),
          _c("span", { staticClass: "demonstration" }, [
            _vm._v("隐藏 Tooltip"),
          ]),
          _c("el-slider", {
            attrs: { "show-tooltip": false },
            model: {
              value: _vm.value3,
              callback: function ($$v) {
                _vm.value3 = $$v
              },
              expression: "value3",
            },
          }),
          _c("span", { staticClass: "demonstration" }, [
            _vm._v("格式化 Tooltip"),
          ]),
          _c("el-slider", {
            attrs: { "format-tooltip": _vm.formatTooltip },
            model: {
              value: _vm.value4,
              callback: function ($$v) {
                _vm.value4 = $$v
              },
              expression: "value4",
            },
          }),
          _c("span", { staticClass: "demonstration" }, [_vm._v("禁用")]),
          _c("el-slider", {
            attrs: { disabled: "" },
            model: {
              value: _vm.value5,
              callback: function ($$v) {
                _vm.value5 = $$v
              },
              expression: "value5",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("离散值")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", { staticClass: "demonstration" }, [
            _vm._v("不显示间断点"),
          ]),
          _c("el-slider", {
            attrs: { step: 10 },
            model: {
              value: _vm.value6,
              callback: function ($$v) {
                _vm.value6 = $$v
              },
              expression: "value6",
            },
          }),
          _c("span", { staticClass: "demonstration" }, [_vm._v("显示间断点")]),
          _c("el-slider", {
            attrs: { "show-stops": "", step: 10 },
            model: {
              value: _vm.value6,
              callback: function ($$v) {
                _vm.value6 = $$v
              },
              expression: "value6",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("带有输入框")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-slider", {
            attrs: { "show-input": "" },
            model: {
              value: _vm.value7,
              callback: function ($$v) {
                _vm.value7 = $$v
              },
              expression: "value7",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("范围选择")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-slider", {
            attrs: { max: 10, range: "", "show-stops": "" },
            model: {
              value: _vm.value8,
              callback: function ($$v) {
                _vm.value8 = $$v
              },
              expression: "value8",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("竖向模式")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-slider", {
            attrs: { height: "200px", vertical: "" },
            model: {
              value: _vm.value9,
              callback: function ($$v) {
                _vm.value9 = $$v
              },
              expression: "value9",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }