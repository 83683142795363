var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "link-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础用法")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-link",
            { attrs: { href: "https://element.eleme.io", target: "_blank" } },
            [_vm._v(" 默认链接 ")]
          ),
          _c("el-link", { attrs: { type: "primary" } }, [_vm._v("主要链接")]),
          _c("el-link", { attrs: { type: "success" } }, [_vm._v("成功链接")]),
          _c("el-link", { attrs: { type: "warning" } }, [_vm._v("警告链接")]),
          _c("el-link", { attrs: { type: "danger" } }, [_vm._v("危险链接")]),
          _c("el-link", { attrs: { type: "info" } }, [_vm._v("信息链接")]),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("禁用状态")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-link", { attrs: { disabled: "" } }, [_vm._v("默认链接")]),
          _c("el-link", { attrs: { disabled: "", type: "primary" } }, [
            _vm._v("主要链接"),
          ]),
          _c("el-link", { attrs: { disabled: "", type: "success" } }, [
            _vm._v("成功链接"),
          ]),
          _c("el-link", { attrs: { disabled: "", type: "warning" } }, [
            _vm._v("警告链接"),
          ]),
          _c("el-link", { attrs: { disabled: "", type: "danger" } }, [
            _vm._v("危险链接"),
          ]),
          _c("el-link", { attrs: { disabled: "", type: "info" } }, [
            _vm._v("信息链接"),
          ]),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("下划线")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-link", { attrs: { underline: false } }, [_vm._v("无下划线")]),
          _c("el-link", [_vm._v("有下划线")]),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("图标")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-link", { attrs: { icon: "el-icon-edit" } }, [_vm._v("编辑")]),
          _c("el-link", [
            _vm._v(" 查看 "),
            _c("i", { staticClass: "el-icon-view el-icon--right" }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }