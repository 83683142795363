var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-number-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础用法")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input-number", {
            attrs: { label: "描述文字", max: 10, min: 1 },
            model: {
              value: _vm.num,
              callback: function ($$v) {
                _vm.num = $$v
              },
              expression: "num",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("禁用状态")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input-number", {
            attrs: { disabled: true },
            model: {
              value: _vm.num2,
              callback: function ($$v) {
                _vm.num2 = $$v
              },
              expression: "num2",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("步数")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input-number", {
            attrs: { step: 2 },
            model: {
              value: _vm.num3,
              callback: function ($$v) {
                _vm.num3 = $$v
              },
              expression: "num3",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("精度")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input-number", {
            attrs: { max: 10, precision: 2, step: 0.1 },
            model: {
              value: _vm.num4,
              callback: function ($$v) {
                _vm.num4 = $$v
              },
              expression: "num4",
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("按钮位置")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-input-number", {
            attrs: { "controls-position": "right", max: 10, min: 1 },
            model: {
              value: _vm.num5,
              callback: function ($$v) {
                _vm.num5 = $$v
              },
              expression: "num5",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }