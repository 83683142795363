var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkbox-container" },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("基础用法")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            },
            [_vm._v("备选项")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("禁用状态")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-checkbox",
            {
              attrs: { disabled: "" },
              model: {
                value: _vm.checked1,
                callback: function ($$v) {
                  _vm.checked1 = $$v
                },
                expression: "checked1",
              },
            },
            [_vm._v("备选项1")]
          ),
          _c(
            "el-checkbox",
            {
              attrs: { disabled: "" },
              model: {
                value: _vm.checked2,
                callback: function ($$v) {
                  _vm.checked2 = $$v
                },
                expression: "checked2",
              },
            },
            [_vm._v("备选项")]
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("多选框组")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.checkList,
                callback: function ($$v) {
                  _vm.checkList = $$v
                },
                expression: "checkList",
              },
            },
            [
              _c("el-checkbox", { attrs: { label: "复选框 A" } }),
              _c("el-checkbox", { attrs: { label: "复选框 B" } }),
              _c("el-checkbox", { attrs: { label: "复选框 C" } }),
              _c("el-checkbox", { attrs: { disabled: "", label: "禁用" } }),
              _c("el-checkbox", {
                attrs: { disabled: "", label: "选中且禁用" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("可选项目数量的限制")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-checkbox-group",
            {
              attrs: { max: 2, min: 1 },
              model: {
                value: _vm.checkedCities,
                callback: function ($$v) {
                  _vm.checkedCities = $$v
                },
                expression: "checkedCities",
              },
            },
            _vm._l(_vm.cities, function (city) {
              return _c("el-checkbox", { key: city, attrs: { label: city } }, [
                _vm._v(" " + _vm._s(city) + " "),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("按钮样式")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.checkboxGroup1,
                callback: function ($$v) {
                  _vm.checkboxGroup1 = $$v
                },
                expression: "checkboxGroup1",
              },
            },
            _vm._l(_vm.cities, function (city) {
              return _c(
                "el-checkbox-button",
                { key: city, attrs: { label: city } },
                [_vm._v(" " + _vm._s(city) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v("带有边框")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-checkbox", {
            attrs: { border: "", label: "备选项1" },
            model: {
              value: _vm.checked3,
              callback: function ($$v) {
                _vm.checked3 = $$v
              },
              expression: "checked3",
            },
          }),
          _c("el-checkbox", {
            attrs: { border: "", label: "备选项2" },
            model: {
              value: _vm.checked4,
              callback: function ($$v) {
                _vm.checked4 = $$v
              },
              expression: "checked4",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }